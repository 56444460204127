* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
  font-size: 10px;
}
body {
  height: 100%;
  font-size: 11px;
  color: #333;
  font-family: 'Source Sans Pro', sans-serif;
  background-color: #f5f5f5;

}

/* ----------------------------------------
    General Layout
   ---------------------------------------- */

#solarfusion {
  display: flex;
  flex: 0 1 100%;
  height: 100%;
}

.grayed-out {
  color: #888;
  cursor: default;
}
.card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  font-size: 1.4rem;
  background: white;
  border-radius: 0.2rem;
  max-height: 100%;
}
.card-title {
  padding: 2rem 1.6rem 1.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3.2rem;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.25;
  text-align: left;
}
.card-subtitle {
  font-size: 1.4rem;
  color: #757575;
}
.card-title div {
  width: 100%;
}

.raised-button {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border: 0;
  outline: none;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  text-transform: uppercase;
  letter-spacing: 0;
  position: relative;
  display: inline-block;
  height: 3.6rem;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  line-height: 3.6rem;
  text-align: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  padding: 1rem;
}
/* ----------------------------------------
    SolarFusion overall
   ---------------------------------------- */
#dashboard {
  height: 100%;
}

 #solarfusion-container {
  display: flex;
  flex-flow: column;
  height: 100%;
  flex: 1;
  max-width: 100%;
 }

/* ----------------------------------------
    Header
   ---------------------------------------- */

#solarfusion-header {
  background-color: #333;
  color: #FFF;
  padding: 1rem;
  display: flex;
  flex-flow: row;
  font-size: 16px;
  width: 100%;
}
.solarfusion-header-logo {
  color: #ffcd43;
  font-size: 24px;
  font-weight: bold;
}
.solarfusion-header-menu {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.solarfusion-header-menu-clickable-item {
  cursor: pointer;
  padding: 1rem 2rem;
  border-radius: 1rem;
}

.solarfusion-header-menu-clickable-item:hover {
  cursor: pointer;
}

.solarfusion-header-menu-item .highlight {
  background-color: #ffcd43; 
  color: #333;
}

/* ----------------------------------------
    Header
   ---------------------------------------- */


.projects-view-table-row {
  display: flex;
  flex-flow: row;
}

.projects-view-table-header-row {
  width: 100%;
}
.projects-view-table-cell {
  width: 20%;
}
.projects-view-grid {
  padding-top: 1rem;
  background-color: #EEE;
  max-width: 100%;
}

.view-switch {
  background-color: #EEE;
  text-align: right;
  
  padding-top: 1rem;
  padding-right: 1rem;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  display: flex;
}
.view-switch-button {
  display: inline-block;
  border: 2px solid #888;
  border-radius: 3px;
  padding: .5rem;
  cursor: pointer;
}
.project-navigation {
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  padding-right: 2rem;
  font-size: 1.5rem
}
.project-navigation i {
  padding-right: 1rem;
  padding-left: 1rem;
}
.project-navigation i:hover {
  cursor: pointer;
}
.project-navigation i.grayed-out {
  cursor: default;
}



/* ----------------------------------------
    Loading placeholder
   ---------------------------------------- */

.loading-placeholder-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {

  width: 80px;
  height: 80px;
  text-align: center;
  font-size: 10px;

}

.spinner > div {
  background-color: #333;
  height: 100%;
  width: 10px;
  display: inline-block;
  margin: 3px;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

/* ----------------------------------------
    Buttons
   ---------------------------------------- */
.action-buttons {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  font-size: 20px;
}

.approve-button {
  padding: .75em;
  color: #FFF;
  margin: 1em;
  cursor: pointer;
}

.deactivated-button {
  padding: .75em;
  background-color: #CCC;
  color: #333;
  margin: 1em;
  cursor: pointer;
}
.project-form-save-button {
  display: inline-block;
  padding: .5rem 1rem;
  font-size: 16px;
  cursor: pointer; 
}

.project-form-edit-button {
  display: inline-block;
  padding: .5rem 1rem;
  font-size: 16px;
  cursor: pointer;
}

.project-form-cancel-button {
  display: inline-block;
  padding: .5rem 1rem;
  font-size: 16px;
  cursor: pointer;
  margin-right: 1rem;
}

.project-form-approve-button {
  display: inline-block;
  padding: .5rem 1rem;
  font-size: 16px;
  margin-left: 4rem;
  cursor: pointer;
}
.project-form-reject-button {
  display: inline-block;
  padding: .5rem 1rem;
  font-size: 16px;
  margin-left: 1rem;
  cursor: pointer;
}

.csv-button {
  color: #FFF;
  padding: .75em;
  margin: 1em;
  cursor: pointer;
}

.edit-button {
  color: blue;
  cursor: pointer;
}

.drag-handle { display: none; }

.row-count {
  width: 100%;
  display: flex;
  flex-flow: row;
  font-size: 20px;
  padding: 1rem;
}


/* ----------------------------------------
    Modal
   ---------------------------------------- */

.project-modal-edit {
  width: 100%;
  margin: auto;
  font-size: 14px;
  padding: 1rem;
}

.project-duplicate-modal {
  width: 100%;
  margin: auto;
  font-size: 14px;
  padding: 1rem;
  display: flex;
  flex-flow: column;
}

.duplicate-modal-close {
  align-self: flex-end;
  cursor: pointer;
  color: #ffcd43;
  position: absolute;
  top:.3rem;
  right:1em;
  font-size:3rem;
}
.project-duplicate-modal-header {
  color: #FFF;
  position: relative;
  text-align: center;
  background-color: #333;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size:1.8rem;
}

.duplicate-modal-project-data-header {
  padding-top:3rem;
}
.duplicate-modal-duplicate-data-header {
  padding-top: 3rem;
}

.modal-row {
  display: flex;
  flex-flow: row;
  padding-bottom: 1rem;
}

.modal-row-label {
  width: 25%;
  font-weight: bold;
  padding: .2rem;
}
.modal-row-input {
  width: 75%;
}

.project-modal-confirm {
  width: 50%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: auto;
  font-size: 24px;
  padding: 4rem;
  border: 1px solid #000;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.approval-confirm-message {
  color: #228b22;
  font-weight: bold;
}

.rejection-confirm-message {
  color: #bb0000;
  font-weight: bold;
}

.project-modal-confirm .action-buttons {
  width: auto;
}

/* ----------------------------------------
    Dashboard panel layout
   ---------------------------------------- */

.project-dashboard-view-container {
  display: flex;
  flex-flow: column;
}
.project-dashboard-row {
  display: flex;
  flex-flow: row;
  width: 100%;
  background-color: #EEE;
}
.panel-header {
  text-align: center;
  background-color: #707070;
  font-size: 16px;
  padding: 1rem;
  color: #FFF;
}

.panel {
  border-radius: 3px;
  border: 1px solid #888;
  margin: 1rem;
  background-color:#FFF;
}

.panel:last-child {
  margin-left: 0;
}
.project-dashboard-row:last-child .panel {
  margin-top: 0;
}

/* ----------------------------------------
    Project Data panel
   ---------------------------------------- */

.project-dashboard-edit-form {
  display: flex;
  flex-flow: column;
  width: 100%;
}
.project-dashboard-edit-form-action-buttons {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  padding-bottom: 1rem;
}

.project-modal-edit {
  display: flex;
  justify-content: space-between;
}

.project-data-fields {
  display: flex;
  flex-flow: column;
  width: 49%;
}

/* ----------------------------------------
    Map panel 
   ---------------------------------------- */

.project-dashboard-map-panel-container {
  width: 100%;
  margin: 1rem;
  border: 1px solid #888;
}

/* ----------------------------------------
    Documentation panel 
   ---------------------------------------- */

.image-thumbnail {
  max-width: 30%;
  border: 2px solid blue;
  padding: .5rem;
  cursor: pointer;
}

.image-thumbnail img {
  max-width: 100%;
}

.project-dashboard-document-panel-container {
  width: 100%;
}

.document-panel-document {
  padding: 1rem;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}

.document-icon {
  font-size: 48px;
  color: #333;
}

.document-list {
  text-align: center;
}

.document-list a {
  text-align: center;
  display: inline-block;
}
.document-list a i {
  display: block;
}
.document-list a span {
  width: 100%;
  display: block;
}

/* ----------------------------------------
    Alerts panel 
   ---------------------------------------- */

.project-dashboard-alerts-panel-container {
  width: 100%;
}

.alerts-panel-alert {
  font-size: 18px;
  padding: .5rem;
}

.alerts-panel-alerts {
  padding: 1rem;
}

/* ----------------------------------------
    Project Grid View
   ---------------------------------------- */

.duplicate-flag {
  cursor: pointer;
}

.inspect-project-button {
  cursor: pointer;
}

/* ----------------------------------------
    Reporting
   ---------------------------------------- */

.report-button {
  font-size: 2rem;
  color: #FFF;
  padding: 1rem 2rem;
  flex-shrink: 1;
  width: 20%;
  text-align: center;
  cursor: pointer;
}

.generate-button {
  background-color: #19a6ff;
}
.download-button {
  background-color: #5CB85C;
}

.reporting-view-buttons {
  padding: 1rem;
  display: flex;
}

#defaultButton {
  background-color: #707070;
  color: white;
  font-weight: bold;
  font-size: small;
}

#positiveButton {
  background-color: #0a7500;
  color: white;
  font-weight: bold;
  font-size: small;
}

#rejectButton {
  background-color: #d40000;
  color: white;
  font-weight: bold;
  font-size: small;
}

#rejectButtonInModal {
  background-color: #d40000;
  color: white;
  font-weight: bold;
  font-size: x-small;
}
.project-form-reject-button-table {
  display: inline-block;
  padding: 0;
  font-size: 16px;
  margin-left: 1rem;
  cursor: pointer;
}

.MuiTableCell-body {
  font-size: x-small !important;
}

.searchBarContainer {
  width: 50%;
  margin: 0 auto;
}

.searchBar {
  width: 100%;
  margin: 0 auto;
  padding-top: 10%;
}

.searchButtonContainer {
  padding-top: 5%;
  width:100%;
  display: flex;
  justify-content: center;
}

#searchButton {
  background-color: #707070;
  color: white;
  font-weight: bold;
  font-size: small;
  margin: 0 auto;
  justify-content: center;
}
